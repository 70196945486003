@import url(//fonts.googleapis.com/css?family=Vibur);
/*@font-face {
    font-family: 'tr2ntr2n';
    src: url('http://peli.podologiacostarica.com/Shalom/tr2n1.woff2') format('woff2'),
         url('http://peli.podologiacostarica.com/Shalom/tr2n1.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
  font-family: 'tr2ntr2n';
  src: local('tr2ntr2n'), url(/static/media/tr2n1.0712ed6a.woff) format('woff');
  src: local('tr2ntr2n'), url(/static/media/tr2n1.1f06bb72.woff2) format('woff2');
}

html,
body {
  height: 100%;
  background-color: #000;
}
body {
  /*background: #112 url(//images.weserv.nl/?url=i.imgur.com/6QJjYMe.jpg)  center no-repeat;*/

  background-size: cover;
  margin: 0;
  overflow: hidden;
}

.flex-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}

.logo {
  text-align: center;
  width: 65%;
  height: 250px;
  margin: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tituloGrande {
  font: 400 19vh 'tr2ntr2n';
  /*font-family: 'tr2ntr2n';
  font-weight:normal;
  font-style:normal;*/
}

.tituloMediano {
  /*font: 400 10vh "Vibur";*/
  font: 400 10vh 'tr2ntr2n';
}

.tituloPequeno {
  /*font: 400 10vh "Vibur";*/
  font: 200 5vh 'tr2ntr2n';
}

.letraNormal {
  font: 200 3vh 'tr2ntr2n';
}

.insignias {
  height: 75px;
  width: 75px;
}

.nameInput {
  background-color: #000;
  color: white;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #33f1ff, 0 0 0.5em #33f1ff,
    0 0 0.1em #33f1ff, 0 10px 3px #000;
  border-color: #33f1ff;
  -webkit-animation: border-flicker 2s linear infinite;
          animation: border-flicker 2s linear infinite;
}

.logo b {
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #33f1ff, 0 0 0.5em #33f1ff,
    0 0 0.1em #33f1ff, 0 10px 3px #000;
}

/*.logo b{
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #cf3dff, 0 0 0.5em #cf3dff, 0 0 0.1em #cf3dff, 0 10px 3px #000;
}*/

.logo b span {
  -webkit-animation: blink linear infinite 2s;
          animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2) {
  -webkit-animation: blink linear infinite 3s;
          animation: blink linear infinite 3s;
}
.logo b span:nth-of-type(3) {
  -webkit-animation: blink linear infinite 4s;
          animation: blink linear infinite 4s;
}
@-webkit-keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79% {
    color: #333;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79% {
    color: #333;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}

/*neon Button*/
/*.a-n2{ 
  font:"Vibur";
  margin-left: 20px;
  text-decoration: none; position: relative;  padding: 15px 30px; overflow: hidden; transition: 0.2s all;  color: #fff; text-transform: uppercase; letter-spacing: 4px;
}

.a-n2 span{
  position: absolute;
  display: block;
}

.a-n2:hover{
  color: #fee;
  background:#713dff; border-color: #fff;
  box-shadow: 0px 0px 10px #713dff, 0px 0px 40px#713dff, 0px 0px 80px #713dff; transition-delay: 0.05s;
}

.a-n2:hover{
  color: #fee;
  background:#4c6cfd00; 
  border-color: #fff;
  box-shadow: 0px 0px 10px #4c6cfd, 0px 0px 40px#4c6cfd, 0px 0px 80px #4c6cfd; transition-delay: 0.05s;
}*/

.a-n2 {
  font: 'Vibur';
  margin-left: 20px;
  letter-spacing: 4px;
  padding: 15px 30px;
  overflow: hidden;
  position: relative;
  text-align: center;
  border-radius: 8px;
  border: 3px solid rgb(0, 40, 70);
  cursor: pointer;
  color: white;
  background-color: #000;
}

.a-n2:hover {
  -webkit-animation: border-flicker 2s linear forwards;
          animation: border-flicker 2s linear forwards;
}

.a-n2:hover .first {
  -webkit-animation: letter-flicker 3s linear infinite;
          animation: letter-flicker 3s linear infinite;
}

@-webkit-keyframes text-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  43% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  46% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  47% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@keyframes text-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  43% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  46% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  47% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@-webkit-keyframes letter-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  40% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@keyframes letter-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  40% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@-webkit-keyframes border-flicker {
  2% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  3% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  5% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  6% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  7% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  9% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  13% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  16% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  18% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  22% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  34% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  36% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  54% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  100% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
}

@keyframes border-flicker {
  2% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  3% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  5% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  6% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  7% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  9% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  13% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  16% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  18% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  22% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  34% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  36% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  54% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
  100% {
    border: 3px solid #33f1ff;
    box-shadow: 0 0 15px -1px #33f1ff, 0 0 12px -1px #33f1ff inset;
  }
}

